//@import url('https://fonts.googleapis.com/css?family=Raleway:300,400,500,600,700');
@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,500,700');
@import url('https://fonts.googleapis.com/css?family=Playfair+Display:400i, 700i');

body {
	font-weight: $font-weight-light;
	font-family: $body-font-family-sans-serif;
	color: $dark;
}

b {
	font-weight: 700;
}

h1,h2,h3,h4,h5,h6{
	font-family: $header-font-family-serif;
    font-weight: $font-weight-base;
	font-style: italic;
    color: $dark;
}

.header-in-body-font {
	font-style: normal;
	font-weight: $font-weight-inbetween;
	font-family: $body-font-family-sans-serif;
}

.display-1,
.display-2,
.display-3,
.display-4,
.display-5,
.display-6 {
	font-weight: 500;
}

//.button {
//	 font-family: 'Montserrat', sans-serif;
//	 font-weight: 700;
//	 font-size: 13px;
//	 text-transform: uppercase;
//	 letter-spacing: 3px;
// }

@each $color,$value in $theme-colors {
    .text-#{$color} {
        @include text-color($value);
    }
}

//blockquote
.blockquote {
	margin-bottom: 0.75rem;
	//font-family: $header-font-family-serif;
	//font-weight: lighter;
	font-size: 1rem;
	font-style: italic;
	color: $dark;
	p {
		&::before {
			content: open-quote;
		}
		&::after {
			content: close-quote;
		}
	}
}
.blockquote-footer {
	font-size: 90%;
	font-style: normal;
	text-align: center;
	&::after {
		content: "\2014\00A0";
	}
	&::after {
		content: "\00A0\2014";
	}
}


.icon {
	position: relative;
	top: .2em;
}

.big-icon {
	font-size: 3rem;
}