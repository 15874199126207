@keyframes rotate { 	
	from {
		transform: rotate(0deg)
		           translate(-10px)
		           rotate(0deg);
	}
	to {
		transform: rotate(360deg)
		           translate(-10px) 
		           rotate(-360deg);
	}
}

.fadein {
    -webkit-animation: fadein 2.5s; /* Safari and Chrome */
    -moz-animation: fadein 2.5s; /* Firefox */
    -ms-animation: fadein 2.5s; /* Internet Explorer */
    -o-animation: fadein 2.5s; /* Opera */
    animation: fadein 2.5s;
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Firefox */
@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Safari and Chrome */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Opera */
@-o-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}
