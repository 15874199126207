.circle {
	width: .6rem;
	height: .6rem;
	border-radius: 50%;
	background-color: $primary;
}
.bubble {
	@include bubble(1.2rem);
}
.big-bubble {
	@include bubble(5rem);
}

.rotate-circle {
	position: absolute;
	animation: rotate 5s linear infinite;
}

.rotate-bubble {
	position: absolute;
	animation: rotate 10s linear infinite;
}

@keyframes wobble-hor-bottom {
	0%,
	100% {
		transform: translateX(0%);
		transform-origin: 50% 50%;
	}
	15% {
		transform: translateX(-30px) rotate(-6deg);
	}
	30% {
		transform: translateX(15px) rotate(6deg);
	}
	45% {
		transform: translateX(-15px) rotate(-3.6deg);
	}
	60% {
		transform: translateX(9px) rotate(2.4deg);
	}
	75% {
		transform: translateX(-6px) rotate(-1.2deg);
	}
}

.rotate-starfish {
	animation: wobble-hor-bottom 0.8s linear both;
}

.flex-even {
	flex: 1;
}

.lead {
	margin-bottom: 0;
}

.card-columns {
	column-gap: 0.75rem;
}

footer hr {
	border-top: 2px solid $light;
}

.alert {
	margin-bottom: 0;
	//border-radius: 0;
}

@each $color,$value in $border-colors {
	.border-#{$color} {
		border: 3px solid $value!important;
	}
}

.social .btn-icon {
	width: 40px;
	height: 40px;
}

.social a {
	color: $white;
	&:hover {
		color: $sea;
	}
}

a {
	color: $dark;
	font-weight: 500;
	&:hover {
		color: $primary;
		text-decoration: none;
	}
}

.collapsible::after {
	content: "\f107";
	font-family: "Font Awesome 5 Free";
	font-weight: 900;
	padding-right: 15px;
}

.collapsible[aria-expanded="true"]::after {
	content: "\f106";
}

.font-weight-inbetween {
	font-weight: $font-weight-inbetween;
}

.sky-container {
	position: relative;
}

.sky-background {
	position: relative;
}

.sky-foreground {
	position: absolute;
	left: 50%;
	top: 0;
}

.slide-top {
	animation: slide-top 2.5s ease-in-out infinite alternate-reverse both;
}

@keyframes slide-top {
	0% {
		transform: translate(-50%, 0);
	}
	100% {
		transform: translate(-50%, -10%);
	}
}

.octopus {
	animation: octopus 2.5s ease-in-out infinite alternate-reverse both;
}

@keyframes octopus {
	0% {
		transform: translateY(-5%);
	}
	100% {
		transform: translateY(5%);
	}
}

footer {
	position: relative;
}

.compass {
	-webkit-transition: -webkit-transform 0.8s ease-in-out;
	transition: transform 0.8s ease-in-out;
}

.compass:hover {
	-webkit-transform: rotate(180deg);
	transform: rotate(180deg);
}

.footer-top {
	position: absolute;
	top: -40px;
	height: 40px;
	width: 100%;
	background-image: url("#{$baseurl}/assets/img/wave.svg");
	background-size: 100% 40px;
	background-repeat: no-repeat;
	background-color: rgba(255, 255, 255, 0);
}



