$baseurl: "";
@import 'custom/variables';
@import 'custom/mixins';

// Core CSS
@import "custom/background";
@import "custom/buttons";
@import "custom/inputs";
@import "custom/typography";
@import "custom/sliders";
@import "custom/checkbox-radio-toggle";
@import "custom/navbars";
@import "custom/tables";
@import "custom/navs";
@import "custom/pagination";
@import "custom/badges";
@import "custom/progress-bars";
@import "custom/alerts";
@import "custom/tooltips";
@import "custom/popovers";
@import "custom/modals";
@import "custom/cards";
@import "custom/dropdown";

//misc
@import "custom/misc";

//components
@import "custom/footers";